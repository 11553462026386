// vue
import { computed, ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type { Nullable, StudyArea } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

// utils
import { deepClone } from '@revolutionprep/utils'

export const useStudyAreaStore = defineStore('study-areas', () => {
  // fetch repositories
  const { $studyAreas } = useNuxtApp()

  // state
  const archivedStudyAreas = ref<StudyArea[]>([])
  const studyArea = ref<Nullable<StudyArea>>(null)
  const studyAreas = ref<StudyArea[]>([])

  // actions
  async function index (config?: NitroFetchOptions<string>) {
    const { studyAreas: _studyAreas } =
      await $studyAreas.index<{ studyAreas: StudyArea[] }>(config)
    if (config?.params?.archived) {
      archivedStudyAreas.value = _studyAreas
    } else {
      studyAreas.value = _studyAreas
    }
    return studyAreas
  }

  async function show (
    id: number,
    config?: NitroFetchOptions<string>
  ) {
    const { studyArea: _studyArea } =
      await $studyAreas.show<{ studyArea: StudyArea }>(id, config)
    studyArea.value = _studyArea
    return studyArea
  }

  async function create (
    payload?: Record<string, unknown>,
    config?: NitroFetchOptions<string>
  ) {
    const { studyArea: _studyArea } =
      await $studyAreas.create<{ studyArea: StudyArea }>(payload, config)
    studyArea.value = _studyArea
    studyAreas.value = [...studyAreas.value, _studyArea]

    return studyArea
  }

  async function update (
    id: number,
    payload?: Record<string, unknown>,
    config?: NitroFetchOptions<string>
  ) {
    const { studyArea: _studyArea } =
      await $studyAreas.update<{ studyArea: StudyArea }>(id, payload, config)
    studyArea.value = _studyArea

    if (studyAreas.value.length) {
      studyAreas.value = studyAreas.value.filter((studyArea) => {
        return studyArea.id !== id
      })
      studyAreas.value = [...studyAreas.value, _studyArea]
    }

    return studyArea
  }

  async function deleteStudyArea (
    id: number,
    payload?: Record<string, unknown>,
    config?: NitroFetchOptions<string>
  ) {
    await $studyAreas.delete(id, payload, config)
    if (studyAreas.value.length) {
      studyAreas.value = studyAreas.value.filter((studyArea) => {
        return studyArea.id !== id
      })
    }
  }

  // getters
  const sortedStudyAreas = computed(() => {
    return deepClone(studyAreas.value).sort((a, b) => {
      const aSubjectName = a.subject?.name || ''
      const bSubjectName = b.subject?.name || ''
      if (aSubjectName < bSubjectName) {
        return -1
      }
      if (aSubjectName > bSubjectName) {
        return 1
      }
      return 0
    })
  })

  return {
    archivedStudyAreas,
    sortedStudyAreas,
    studyArea,
    studyAreas,
    create,
    deleteStudyArea,
    index,
    show,
    update
  }
})
